import React from 'react';

import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_HOME_PAGE_BY_ID, LIST_ALL_TERMS } from '../../queries';
import { contentSquereCustomTag, artificialPageview } from '../../helpers'
import { useConfiguration } from '../../hooks/useConfiguration';

export const useMainRoute = () => {
  const { storeId = '', harvestId = '' } = useParams();
  const { HomePageTemplate } = useConfiguration({ storeId, harvestId });

  const { data, loading } = useQuery(LIST_ALL_TERMS);

  const [categories, setCategories] = React.useState();

  let fullpath;
  HomePageTemplate == undefined || HomePageTemplate?.fullpath == '' ? fullpath = '/Home Pages/_default' : fullpath = HomePageTemplate?.fullpath;

  const { data: homePageStructure, loading: homePageLoading } = useQuery(GET_HOME_PAGE_BY_ID,
    {
      variables: {
        fullpath: fullpath,
      },
    }
  )
  const [homePage, setHomePage] = React.useState();
  React.useEffect(() => {
    if (!homePageLoading) {
      const edges = homePageStructure.getHomePage;
      setHomePage(edges);
    }
  }, [homePageLoading, homePageStructure, harvestId, storeId]);

  React.useEffect(() => {
    if (!loading) {
      const edges = data?.getEPogTermListing?.edges;

      const categories = edges
        ?.filter(
          (edge: any) =>
            edge?.node?.fullpath?.includes(storeId) &&
            edge?.node?.fullpath?.includes(harvestId) &&
            edge?.node?.fullpath?.includes('Category')
        )
        .map((value: any) => ({ value, sort: Math.random() }))
        .sort((a: any, b: any) => a.sort - b.sort)
        .map(({ value }: any) => value);
      setCategories(categories);
    }
  }, [loading, data, harvestId, storeId]);

  React.useEffect(() => {
    contentSquereCustomTag(1,'Path', window.location.pathname + window.location.hash.replace('#', '?__'), '')
    contentSquereCustomTag(2,'pageType','HomePage','')
    artificialPageview(window.location.pathname + window.location.hash.replace('#', '?__'))
  }, [])

  return {
    loading,
    categories,
    homePage
  };
};
