import { HomeCategoriesSlider } from "./HomeCategoriesSlider";
import { HomeMemberPerks } from "./HomeMemberPerks";
import { HomeFooterSection } from "./HomeFooterSection";
import styled from "@emotion/styled";
import { theme } from "../../theme";
import { useMainRoute } from "./useMainRoute";
import { ICategory, IProduct } from "../../types";
import { PopularProducts } from "../../components/PopularProducts";
import { Carousel } from "../../components/Carousel";
import DOMPurify from "dompurify";

const sanitizedData = (data: any) => ({
  __html: DOMPurify.sanitize(data, { FORBID_TAGS: ['script'] }),
});

export const MainRoute = () => {
  const {
    categories,
    homePage
  }: {
    categories?: { node: ICategory }[];
    homePage: any;
  } = useMainRoute();

  return (
    <StyledComponentWrapper>
      <HomeMemberPerks />
      {categories && <HomeCategoriesSlider categories={categories} />}
      {homePage && (
          <style>{homePage.Styles}</style>
        )}
        {
          homePage && (
            homePage.Elements.map((item: any, index: number) => {
              if (item.__typename == 'fieldcollection_HomePageElementSlider') {
                return (<PopularProducts products={item.SliderProducts} title={item.Title}></PopularProducts>);
              }
              if (item.__typename == 'fieldcollection_HomePageElementCarousel') {
                return (<div className="carousel-container">
                  <Carousel CarouselItems={item.CarouselItems}></Carousel>
                </div>);
              }
              if (item.__typename == 'fieldcollection_HomePageElementRT') {
                return (<div dangerouslySetInnerHTML={sanitizedData(item.Content)} className='top'>
                </div>);
              }
            })
          )
        }
      <HomeFooterSection />
    </StyledComponentWrapper>
  );
};

const StyledComponentWrapper = styled.div`
  min-height: 100%;
  max-width: 100vw;
  background-color: ${theme.samsclub.greyLight};
  box-sizing: border-box;
  margin-top: 78px;
`;
