import React from 'react';

import { useQuery } from '@apollo/client';
import { useLocation, useParams } from 'react-router-dom';
import {
  GET_PRODUCT_BY_ID,
  GET_PRODUCT_CATEGORY_BY_TERM,
} from '../../queries';
import { contentSquereCustomTag, artificialPageview } from '../../helpers'

export const usePdpRoute = () => {
  const { productId, categoryId = '', storeId, harvestId } = useParams();
  const { pathname } = useLocation();

  const { data: categoryData, loading: categoryLoading } = useQuery(
    GET_PRODUCT_CATEGORY_BY_TERM({
      termId: productId?.split('__')[0],
    }),
    {
      skip: !categoryId || !categoryId.length || pathname.includes('ips'),
    }
  );

  const { data, loading } = useQuery(GET_PRODUCT_BY_ID, {
    variables: {
      getEPogId: Number(productId?.split('__')[0]),
    },
  });

  React.useEffect(() => {
    contentSquereCustomTag(1, 'Path', window.location.pathname + window.location.hash.replace('#', '?__'), '')
    contentSquereCustomTag(2, 'pageType', 'PDP', '');
    artificialPageview(window.location.pathname + window.location.hash.replace('#', '?__'))
  }, [pathname])

  return {
    loading,
    categoryLoading,
    categoryData,
    data,
    categoryId,
  };
};
